import CTABanner from "components/common/footer-banners/CTABanner";
import DefaultHeroLayout from "components/common/hero/DefaultHeroLayout";
import SupportSection from "components/common/SupportSection";
import LicenseTable from "components/license-comparison/LicenseTable";
import GlobalLayout from "components/page/GlobalLayout";
import React from "react";
import { Helmet } from "react-helmet";

export default function CameraslicenseComparison() {
  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>Rhombus License Comparison - Video Intercoms</title>
        <meta
          name="description"
          content="Determine Which License Fits Your Business Requirements"
        />
      </Helmet>
      <DefaultHeroLayout
        preTitle="License Comparison"
        title="Determine Which License Fits Your Business Requirements"
        backgroundColor="var(--gradient-light-fade)"
        center
      />
      <LicenseTable selectedTab="Video Intercom" />
      <SupportSection />
      <CTABanner />
    </GlobalLayout>
  );
}
